export enum PopupWindowType {
    LOAD_LABEL_NAMES = "LOAD_LABEL_NAMES",
    UPDATE_LABEL = "UPDATE_LABEL",
    SUGGEST_LABEL_NAMES = "SUGGEST_LABEL_NAMES",
    IMPORT_IMAGES = "IMPORT_IMAGES",
    LOAD_AI_MODEL = "LOAD_AI_MODEL",
    EXPORT_ANNOTATIONS = "EXPORT_ANNOTATIONS",
    IMPORT_ANNOTATIONS = "IMPORT_ANNOTATIONS",
    INSERT_LABEL_NAMES = 'INSERT_LABEL_NAMES',
    EXIT_PROJECT = 'EXIT_PROJECT',
    LOADER = 'LOADER'
}